@import "../../../variables";
@import "../../../mixins";

$field-padding: 0.6em 1.2em;
$field-font-size: 1.8em;

#contact {
  margin-block: 3rem;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    bottom: -50px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/svgs/curvesbg.svg);
    background-size: cover;
    z-index: 0;
  }
}

.contact-container {
  padding-inline: 5vw;

  font-size: 0.5rem;

  @include above_breakpoint(m) {
    font-size: 10px;
  }
}

.contact-main-grid {
  display: grid;
  text-align: center;

  gap: 2rem;

  @include above_breakpoint(l) {
    grid-template-columns: 1fr 2fr;
    align-items: center;
    justify-content: center;
  }
}

.contact-title {
  @include title_text;
  z-index: 1;
}

.contact-subtitle {
  @include subtitle_text;
  z-index: 1;
}

.contact-form-container {
  border-radius: 10em 0 10em 0;
  background: $bgSecondary;

  padding-block: 5em;
  padding-inline: 5em;
  max-width: 800px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > textarea {
    resize: vertical;
    overflow: auto;
    font-family: inherit;
  }
}

.contact-form-field {
  font-size: $field-font-size;
  padding: $field-padding;
  border: none;
  border-radius: 5px;
  color: $textPrimary;
  z-index: 1;

  &:focus {
    outline: none !important;
    box-shadow: 0 0 10px rgba(darken($primary, 10%), 1);
  }
}

.contact-form-submit-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.8em 1.6em;
  font-size: 2em;
  gap: 0.5em;
  border: none;
  border-radius: 50px;
  background-color: transparent;
  color: $textPrimary;
  align-self: flex-end;
  font-weight: 600;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  cursor: pointer;

  &::before {
    position: absolute;
    content: "";
    background: $primary;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0px;
    left: 0px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;

    @include above_breakpoint(l) {
      width: 40%;
    }
  }

  @include above_breakpoint(l) {
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
}

@include keyframes(spinner) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contact-form-spinner {
  @include animation("spinner 1s infinite ease");
}
