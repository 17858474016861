@import "../../../mixins";
@import "../../../variables";

#experience {
  margin-block: 1vh;
  font-size: 0.5rem;

  @include above_breakpoint(m) {
    font-size: 0.625rem;
  }
}

.work-container {
  background: $bgSecondary;
  border-radius: 14em 0 14em 0;
  padding-right: 5vw;
  padding-block: 10vw;
  display: flex;
  flex-direction: column;
  gap: 5em;

  @include above_breakpoint(xl) {
    flex-direction: row;
    padding-block: 0vw;
    padding-right: 0vw;
    padding-inline: 2vw;
  }
}

.work-title-container {
  align-self: center;
  text-align: center;

  @include above_breakpoint(xl) {
    min-width: 25%;
  }
}

.work-title {
  @include title_text;
  position: relative;

  // &::before {
  //   content: "";
  //   width: 20%;
  //   height: 5px;
  //   background-color: $hint;
  //   position: absolute;
  //   top: 100%;
  //   left: 0;
  //   border-radius: 50px;
  // }
}

.work-subtitle {
  @include subtitle_text;
}

.work-timeline-container {
  transform: (translateX(-25px));

  @include above_breakpoint(xl) {
    transform: (translateX(0px));
  }
}
