@import "../../../variables.scss";
@import "../../../mixins.scss";

#home {
  overflow-x: hidden;
}

.hero-container {
  height: 110vh;
  width: 100%;
  position: relative;

  font-size: 8px;

  @include above_breakpoint(m) {
    font-size: 10px;
  }

  @include above_breakpoint(xl) {
    font-size: 16px;
  }

  .hero-bg {
    top: 0;
    left: 0;
    clip-path: polygon(100% 0, 100% 90%, 49% 100%, 0 90%, 0 0);
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $bgSecondary;
    z-index: -1;
  }
}

.hero-container-2 {
  height: 100vh;
  display: grid;
  place-content: center;
  margin-inline: 5vw;
  position: relative;

  .hero-main-grid {
    display: grid;
    grid-template-rows: 1.3fr 1fr;
    align-items: center;
    justify-content: space-between;
    grid-template-areas:
      "illustration"
      "info";
    row-gap: 2rem;

    @include above_breakpoint(s) {
      grid-template-columns: 1.3fr 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "info illustration";
    }
  }
}

.hero-info-container {
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;

  @include above_breakpoint(l) {
    gap: 1.6rem;
  }
}

.hero-name-container {
  position: relative;

  &::after {
    content: "";
    width: 13.12em;
    height: 6.8em;
    background: url("../../../assets/svgs/hero-name-bg.svg");
    position: absolute;
    top: 0;
    z-index: -1;
    transform: translateX(-60%) translateY(-25%);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .filler-text {
    color: $hint;
    font-size: 2em;
    font-weight: 600;

    @include above_breakpoint(xl) {
      font-size: 1.5em;
    }
  }

  .name {
    display: inline-block;
    color: $textSecondary;
    margin-block: 0;
    font-weight: 600;
    font-size: 4.5em;

    @include above_breakpoint(xl) {
      font-size: 4em;
    }
  }
}

.Typewriter {
  display: inline-block;
}

.adjective-container {
  color: $textPrimary;
  font-weight: 600;
  font-size: 3em;

  @include above_breakpoint(xl) {
    font-size: 2em;
  }
}

.hero-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.hero-illustration-container {
  display: grid;
  place-content: center;

  .illustration {
    margin: auto;
    height: 90%;
    width: 90%;

    @include above_breakpoint(xl) {
      width: 100%;
      height: 100%;
    }
  }
}

.socials-container {
  display: flex;
  flex-direction: row;
  gap: 3em;
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);

  @include above_breakpoint(s) {
    gap: 1.5rem;
    flex-direction: column;
    bottom: 1rem;
    left: 0;
    transform: translateX(-4vw);
  }
}

.social-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: $textSecondary;
  font-weight: 600;
  padding: 0.5rem;
  position: relative;
  -webkit-tap-highlight-color: transparent;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    // top: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    border-radius: 50px;
    background-color: transparent;
    width: 2.6em;
    height: 100%;

    @include above_breakpoint(s) {
      background-color: $primary;
    }
  }

  &:hover {
    &:before {
      background-color: $primary;
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    @include above_breakpoint(s) {
      .username {
        display: inline-block;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.social-icon {
  height: 4em;
  width: 4em;

  @include above_breakpoint(s) {
    height: 3.3em;
    width: 3.3em;
  }

  @include above_breakpoint(xl) {
    height: 2.2em;
    width: 2.2em;
  }
}

.username {
  display: none;
  padding-right: 0.5rem;
  transition: width 0.3s ease-in-out;
  color: $textPrimary;
}
