@import "../../variables.scss";
@import "../../mixins.scss";

.pillBtn {
  background-color: $primary;
  color: $textPrimary;
  font-weight: 600;
  cursor: pointer;
  padding: 0;
  border: 0px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  width: 150px;
  height: 50px;
  font-size: 2em;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;
  text-align: center;

  @include above_breakpoint(m) {
    width: 180px;
    height: 60px;
  }

  @include above_breakpoint(xl) {
    width: 240px;
    height: 80px;
    font-size: 1.5em;
  }

  &:hover {
    background-color: $secondary;
    color: $primary;
    box-shadow: -0.1rem 0.1rem 0.2rem lighten($secondary, 2%);
  }
}

.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  @include animation(ripple 0.6s linear);
  background-color: rgba(#ffffff, 0.7);
}

@include keyframes(ripple) {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
